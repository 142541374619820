import type { SiteConfig } from '@config/type';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { ESTATE } from '@type/search/filters/estate';
import { TRANSACTION } from '@type/search/filters/transaction';
import { COMMERCIAL_PROPERTY_USE_TYPE } from '@type/search/filters/useTypes';

export * from '@config/type';

export const SITE_CONFIG: SiteConfig = {
    metadata: {
        preconnectStaticsCDN: 'https://statics.storia.ro',
        siteURL: 'www.storia.ro',
        siteName: 'Storia',
        defaultLanguage: 'ro',
        name: {
            robots: 'index, follow',
            description:
                'Descopera cele mai bune apartamente si case din Romania pe site-ul premium de imobiliare lansat de OLX. Ai la dispozitie 247356 oferte din care poti alege. Gaseste-ti casa visurilor tale pe Storia!',
            'google-play-app': 'app-id=ro.storia',
            'theme-color': '#464b53',
            'msapplication-TileColor': '#464b53',
        },
        property: {
            'fb:app_id': '1758851897729052',
            'og:title': 'Storia.ro - anunturi imobiliare pentru apartamente, case, terenuri',
            'og:description':
                'Descopera cele mai bune apartamente si case din Romania pe site-ul premium de imobiliare lansat de OLX. Ai la dispozitie 247356 oferte din care poti alege. Gaseste-ti casa visurilor tale pe Storia!',
            'og:image':
                'https://statics.storia.ro/static/storiaro/naspersclassifieds-regional/verticalsre-atlas-web-storiaro/static/img/fb/fb-image200x200.png?t=20-11-10',
            'og:type': 'website',
            'og:url': 'https://www.storia.ro/',
            'og:site_name': 'www.storia.ro/',
            'al:web:url': 'https://www.storia.ro/',
            'al:web:should_fallback': 'true',
        },
        links: [
            {
                rel: 'canonical',
                href: 'https://www.storia.ro/',
            },
            {
                rel: 'icon',
                href: 'https://statics.storia.ro/static/storiaro/naspersclassifieds-regional/verticalsre-atlas-web-storiaro/static/img/favicon.ico?v=5',
            },
            {
                rel: 'apple-touch-icon',
                href: 'https://statics.storia.ro/static/storiaro/naspersclassifieds-regional/verticalsre-atlas-web-storiaro/static/img/app-icon.png',
            },
            {
                rel: 'android-touch-icon',
                href: 'https://statics.storia.ro/static/storiaro/naspersclassifieds-regional/verticalsre-atlas-web-storiaro/static/img/app-icon.png',
            },
        ],
        sellWithUs: false,
    },
    locales: [
        {
            code: 'ro',
            label: 'frontend.navbar.language-switcher.ro',
        },
    ],
    helpCenterLinks: [
        {
            code: 'ro',
            link: 'https://help.storia.ro/storiahelp/s/article/kA009000001vPEDCA2',
        },
    ],
    helpCenterPaymentPageLinks: {
        partner: 'https://help.storia.ro/storiahelp/s/',
        learnMore: 'https://help.storia.ro/storiahelp/s/contactsupport',
        learnAboutAds: 'https://pomoc.otodom.pl/otodomhelpcenter/s/article/co-to-jest-wystawienie-V40-odo',
    },
    settingsPageLink: '/[lang]/contul-meu/setari',
    helpCenterSortingInfoLinks: 'https://help.storia.ro/storiahelp/s/article/kA009000001vPG4CAM',
    tracking: {
        ninjaScriptUrl: 'https://ninja.data.olxcdn.com/ninja-real-estate.js',
        siteUrl: `${getPublicEnvConfig().env === 'PRD' ? 'www' : 'stg'}.storia.ro`,
    },
    defaultUnits: {
        distance: 'm',
        area: 'm²',
        currency: '€',
    },
    dfp: {
        networkId: '/71406437/Storia.ro',
        type: {
            adPage: {
                halfpage: '/AdPage//Halfpage//Rectangle1',
                halfpageSec: '/AdPage//Halfpage//Rectangle2',
                billboard: '/AdPage//Bilboard',
                native: '/AdPage//Rectangle1',
                textLink: '/AdPage//Mobile300x250',
                textBottom: '/AdPage//Textlink//Bottom',
                textTop: '/AdPage//Textlink//Top',
                calculator: '/AdPage//Calculator',
            },
            searchPage: {
                additionalBox: 'Listing-HalfPage-Rectangle1',
                rectangle: 'Listing-Rectangle2',
                calculator: 'Listing-Calculator',
                billboardBottom: 'Listing-FooterBillboard',
            },
            myAccountPage: {},
        },
    },
    phoneNumbers: {
        countryCode: '40',
        country: 'RO',
        format: /^[1-9](?!0{8})\d{8}$/,
    },
    images: {
        logo: '/images/logo/storia_logo_white.svg',
        nexusLogo: '/images/logo/storia_logo_black.svg',
        homepageBackground: '/images/homepage/storiaHomepageBackground20240820.webp',
        investmentsPageBackground: '/images/investments/storiaInvestmentsBackground.webp',
    },
    navbar: [
        {
            label: {
                default: 'frontend.navbar.menu.sell',
            },
            id: 'sell',
            tracking: {
                eventName: 'for_sale_click',
                touchPointButton: 'header',
            },
            submenu: [
                {
                    label: {
                        default: 'frontend.navbar.menu.estate',
                    },
                    id: 'sellEstate',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_categories',
                        categoryName: 'sale',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-apartments',
                            },
                            id: 'sellEstateApartments',
                            href: '/[lang]/rezultate/vanzare/apartament/toata-romania',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'toata-romania',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-garsoniera',
                            },
                            id: 'sellEstateStudioFlat',
                            href: '/[lang]/rezultate/vanzare/garsoniere/toata-romania',
                            searchData: {
                                estate: ESTATE.studioFlat,
                                transaction: TRANSACTION.sell,
                                location: 'toata-romania',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-house',
                            },
                            id: 'sellEstateHouse',
                            href: '/[lang]/rezultate/vanzare/casa/toata-romania',
                            searchData: {
                                estate: ESTATE.house,
                                transaction: TRANSACTION.sell,
                                location: 'toata-romania',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-plots',
                            },
                            id: 'sellEstatePlots',
                            href: '/[lang]/rezultate/vanzare/teren/toata-romania',
                            searchData: {
                                estate: ESTATE.terrain,
                                transaction: TRANSACTION.sell,
                                location: 'toata-romania',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-commercials',
                            },
                            id: 'sellEstateCommercials',
                            href: '/[lang]/rezultate/vanzare/spatiu-comercial/toata-romania',
                            searchData: {
                                estate: ESTATE.commercialProperty,
                                transaction: TRANSACTION.sell,
                                location: 'toata-romania',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-office',
                            },
                            id: 'sellEstateOffice',
                            href: '/[lang]/rezultate/vanzare/spatiu-comercial,birou/toata-romania',
                            searchData: {
                                estate: ESTATE.commercialProperty,
                                transaction: TRANSACTION.sell,
                                location: 'toata-romania',
                                useTypes: [COMMERCIAL_PROPERTY_USE_TYPE.office],
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-halls',
                            },
                            id: 'sellEstateHalls',
                            href: '/[lang]/rezultate/vanzare/depozite-hale/toata-romania',
                            searchData: {
                                estate: ESTATE.hall,
                                transaction: TRANSACTION.sell,
                                location: 'toata-romania',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'sale',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.popular-location',
                    },
                    id: 'sellPopularLocation',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_popular_locations',
                        categoryName: 'sale',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-bucuresti',
                            },
                            id: 'sellPopularLocationBucuresti',
                            href: '/[lang]/rezultate/vanzare/apartament/bucuresti',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'bucuresti',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-iasi',
                            },
                            id: 'sellPopularLocationIasi',
                            href: '/[lang]/rezultate/vanzare/apartament/iasi',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'iasi',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-cluj-napoca',
                            },
                            id: 'sellPopularLocationClujNapoca',
                            href: '/[lang]/rezultate/vanzare/apartament/cluj/cluj--napoca',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'cluj/cluj--napoca',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-timisoara',
                            },
                            id: 'sellPopularLocationTimisoara',
                            href: '/[lang]/rezultate/vanzare/apartament/timis/timisoara',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'timis/timisoara',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-constanta',
                            },
                            id: 'sellPopularLocationConstanta',
                            href: '/[lang]/rezultate/vanzare/apartament/constanta/constanta',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'constanta/constanta',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-craiova',
                            },
                            id: 'sellPopularLocationCraiova',
                            href: '/[lang]/rezultate/vanzare/apartament/dolj/craiova',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'dolj/craiova',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-brasov',
                            },
                            id: 'sellPopularLocationBrasov',
                            href: '/[lang]/rezultate/vanzare/apartament/brasov/brasov',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'brasov/brasov',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-galati',
                            },
                            id: 'sellPopularLocationGalati',
                            href: '/[lang]/rezultate/vanzare/apartament/galati/galati',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'galati/galati',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'sale',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.sell-investment',
                    },
                    id: 'sellInvestment',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_investments',
                        categoryName: 'sale',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.sell-new-apartment',
                            },
                            id: 'sellInvestmentApartment',
                            href: '/[lang]/rezultate/vanzare/apartament,noi/toata-romania',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'toata-romania',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_investments',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.sell-new-house',
                            },
                            id: 'sellInvestmentHouse',
                            href: '/[lang]/rezultate/vanzare/casa,noi/toata-romania',
                            searchData: {
                                estate: ESTATE.house,
                                transaction: TRANSACTION.sell,
                                market: 'PRIMARY',
                                location: 'toata-romania',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_investments',
                                categoryName: 'sale',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.sell-guide-header',
                    },
                    id: 'sellGuide',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_blog',
                        categoryName: 'sale',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.useful-built-article',
                            },
                            id: 'sellGuideBuiltArticle',
                            href: 'https://www.storia.ro/blog/ghid-cumparare/utile/diferenta-dintre-suprafata-construita-si-utila',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.old-or-new-article',
                            },
                            id: 'sellGuideOldOrNewArticle',
                            href: 'https://www.storia.ro/blog/ghid-cumparare/utile/apartament-bloc-vechi-sau-bloc-nou-avantaje-dezavantaje',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                categoryName: 'sale',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.flats-article',
                            },
                            id: 'sellGuideFlatsArticle',
                            href: 'https://www.storia.ro/blog/ghid-cumparare/utile/locuinte-la-parter-avantaje-dezavantaje',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                categoryName: 'sale',
                            },
                        },
                    ],
                    link: {
                        label: {
                            default: 'frontend.navbar.menu.see-all-button',
                            mobile: 'frontend.navbar.menu.sell-guide-header',
                        },
                        id: 'sellGuideSeeAll',
                        href: 'https://www.storia.ro/blog/ghid-cumparare',
                        tracking: {
                            eventName: 'blog_click',
                            touchPointButton: 'header',
                            categoryName: 'sale',
                        },
                    },
                },
            ],
        },
        {
            label: {
                default: 'frontend.navbar.menu.rent',
            },
            id: 'rent',
            tracking: {
                eventName: 'for_rent_click',
                touchPointButton: 'header',
            },
            submenu: [
                {
                    label: {
                        default: 'frontend.navbar.menu.estate',
                    },
                    id: 'rentEstate',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_categories',
                        categoryName: 'rent',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-apartments',
                            },
                            id: 'rentEstateApartments',
                            href: '/[lang]/rezultate/inchiriere/apartament/toata-romania',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'toata-romania',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-garsoniera',
                            },
                            id: 'rentEstateStudioFlat',
                            href: '/[lang]/rezultate/inchiriere/garsoniere/toata-romania',
                            searchData: {
                                estate: ESTATE.studioFlat,
                                transaction: TRANSACTION.rent,
                                location: 'toata-romania',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-house',
                            },
                            id: 'rentEstateHouse',
                            href: '/[lang]/rezultate/inchiriere/casa/toata-romania',
                            searchData: {
                                estate: ESTATE.house,
                                transaction: TRANSACTION.rent,
                                location: 'toata-romania',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-room',
                            },
                            id: 'rentEstateRoom',
                            href: '/[lang]/rezultate/inchiriere/camera/toata-romania',
                            searchData: {
                                estate: ESTATE.room,
                                transaction: TRANSACTION.rent,
                                location: 'toata-romania',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-plots',
                            },
                            id: 'rentEstatePlots',
                            href: '/[lang]/rezultate/inchiriere/teren/toata-romania',
                            searchData: {
                                estate: ESTATE.terrain,
                                transaction: TRANSACTION.rent,
                                location: 'toata-romania',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-commercials',
                            },
                            id: 'rentEstateCommercials',
                            href: '/[lang]/rezultate/inchiriere/spatiu-comercial/toata-romania',
                            searchData: {
                                estate: ESTATE.commercialProperty,
                                transaction: TRANSACTION.rent,
                                location: 'toata-romania',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-office',
                            },
                            id: 'rentEstateOffice',
                            href: '/[lang]/rezultate/inchiriere/spatiu-comercial,birou/toata-romania',
                            searchData: {
                                estate: ESTATE.commercialProperty,
                                transaction: TRANSACTION.rent,
                                location: 'toata-romania',
                                useTypes: [COMMERCIAL_PROPERTY_USE_TYPE.office],
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-halls',
                            },
                            id: 'rentEstateHalls',
                            href: '/[lang]/rezultate/inchiriere/depozite-hale/toata-romania',
                            searchData: {
                                estate: ESTATE.hall,
                                transaction: TRANSACTION.rent,
                                location: 'toata-romania',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_categories',
                                categoryName: 'rent',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.popular-location',
                    },
                    id: 'rentPopularLocation',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_popular_locations',
                        categoryName: 'rent',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-bucuresti',
                            },
                            id: 'rentPopularLocationBucuresti',
                            href: '/[lang]/rezultate/inchiriere/apartament/bucuresti',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'bucuresti',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-iasi',
                            },
                            id: 'rentPopularLocationIasi',
                            href: '/[lang]/rezultate/inchiriere/apartament/iasi',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'iasi',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-cluj-napoca',
                            },
                            id: 'rentPopularLocationClujNapoca',
                            href: '/[lang]/rezultate/inchiriere/apartament/cluj/cluj--napoca',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'cluj/cluj--napoca',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-timisoara',
                            },
                            id: 'rentPopularLocationTimisoara',
                            href: '/[lang]/rezultate/inchiriere/apartament/timis/timisoara',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'timis/timisoara',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-constanta',
                            },
                            id: 'rentPopularLocationConstanta',
                            href: '/[lang]/rezultate/inchiriere/apartament/constanta',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'constanta/constanta',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-craiova',
                            },
                            id: 'rentPopularLocationCraiova',
                            href: '/[lang]/rezultate/inchiriere/apartament/dolj/craiova',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'dolj/craiova',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-brasov',
                            },
                            id: 'rentPopularLocationBrasov',
                            href: '/[lang]/rezultate/inchiriere/apartament/brasov',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'brasov/brasov',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-galati',
                            },
                            id: 'rentPopularLocationGalati',
                            href: '/[lang]/rezultate/inchiriere/apartament/galati',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.rent,
                                location: 'galati/galati',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                categoryName: 'rent',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.rent-popular-agency',
                    },
                    id: 'rentPopularAgency',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_agencies',
                        categoryName: 'rent',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-agency-bucuresti',
                                mobile: 'frontend.navbar.menu.location-bucuresti',
                            },
                            id: 'rentPopularAgencyBucuresti',
                            href: '/bucuresti/companii/dezvoltatori/bucuresti/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_agencies',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-agency-iasi',
                                mobile: 'frontend.navbar.menu.location-iasi',
                            },
                            id: 'rentPopularAgencyIasi',
                            href: '/companii/agentii/iasi/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_agencies',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-agency-cluj-napoca',
                                mobile: 'frontend.navbar.menu.location-cluj-napoca',
                            },
                            id: 'rentPopularAgencyClujNapoca',
                            href: '/companii/agentii/cluj/cluj-napoca/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_agencies',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-agency-timisoara',
                                mobile: 'frontend.navbar.menu.location-timisoara',
                            },
                            id: 'rentPopularAgencyTimisoara',
                            href: '/companii/agentii/timis/timisoara/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_agencies',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-agency-constanta',
                                mobile: 'frontend.navbar.menu.location-constanta',
                            },
                            id: 'rentPopularAgencyConstanta',
                            href: '/companii/agentii/constanta/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_agencies',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-agency-craiova',
                                mobile: 'frontend.navbar.menu.location-craiova',
                            },
                            id: 'rentPopularAgencyCraiova',
                            href: '/companii/agentii/craiova/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_agencies',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-agency-brasov',
                                mobile: 'frontend.navbar.menu.location-brasov',
                            },
                            id: 'rentPopularAgencyBrasov',
                            href: '/companii/agentii/brasov/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_agencies',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-agency-galati',
                                mobile: 'frontend.navbar.menu.location-galati',
                            },
                            id: 'rentPopularAgencyGalati',
                            href: '/companii/agentii/galati/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_agencies',
                                categoryName: 'rent',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.rent-guide-header',
                    },
                    id: 'rentGuide',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_blog',
                        categoryName: 'rent',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-analytics-article',
                            },
                            id: 'rentGuideAnalyticsArticle',
                            href: 'https://www.storia.ro/blog/ghid-chirie/chirias/garantia-la-contractul-de-inchiriere',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.rent-tips-article',
                            },
                            id: 'rentGuideTipsArticle',
                            href: 'https://www.storia.ro/blog/ghid-chirie/chirias/sfaturi-inchiriere-apartament',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                categoryName: 'rent',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.tenant-rights-article',
                            },
                            id: 'rentGuideTenantRightsArticle',
                            href: 'https://www.storia.ro/blog/ghid-chirie/chirias/drepturile-chiriasului',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                categoryName: 'rent',
                            },
                        },
                    ],
                    link: {
                        label: {
                            default: 'frontend.navbar.menu.see-all-button',
                            mobile: 'frontend.navbar.menu.rent-guide-header',
                        },
                        id: 'rentGuideSeeAll',
                        href: 'https://www.storia.ro/blog/ghid-chirie',
                        tracking: {
                            eventName: 'blog_click',
                            touchPointButton: 'header',
                            categoryName: 'rent',
                        },
                    },
                },
            ],
        },
        {
            label: {
                default: 'frontend.navbar.menu.primary-market',
            },
            id: 'primaryMarket',
            tracking: {
                eventName: 'primary_market_click',
                touchPointButton: 'header',
            },
            submenu: [
                {
                    label: {
                        default: 'frontend.navbar.menu.estate',
                    },
                    id: 'primaryMarketEstate',
                    tracking: {
                        eventName: 'header_categories',
                        touchPointButton: 'title_click',
                        market: 'primary',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-apartments',
                            },
                            id: 'primaryMarketEstateApartments',
                            href: '/[lang]/rezultate/vanzare/apartament,noi/toata-romania',
                            searchData: {
                                estate: ESTATE.flat,
                                transaction: TRANSACTION.sell,
                                location: 'toata-romania',
                                market: 'PRIMARY',
                            },
                            tracking: {
                                eventName: 'header_categories',
                                touchPointButton: 'seo_link_click',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.estate-house',
                            },
                            id: 'primaryMarketEstateHouse',
                            tracking: {
                                eventName: 'header_categories',
                                touchPointButton: 'seo_link_click',
                                market: 'primary',
                            },
                            href: '/[lang]/rezultate/vanzare/casa,noi/toata-romania',
                            searchData: {
                                estate: ESTATE.house,
                                transaction: TRANSACTION.sell,
                                location: 'toata-romania',
                                market: 'PRIMARY',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.popular-location',
                    },
                    id: 'primaryMarketPopularLocations',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_popular_locations',
                        market: 'primary',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-bucuresti',
                            },
                            id: 'primaryMarketPopularLocationsBucuresti',
                            href: '/[lang]/rezultate/vanzare/investitii/bucuresti',
                            searchData: {
                                estate: ESTATE.investment,
                                transaction: TRANSACTION.sell,
                                location: 'bucuresti',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-iasi',
                            },
                            id: 'primaryMarketPopularLocationsIasi',
                            href: '/[lang]/rezultate/inchiriere/investitii/iasi',
                            searchData: {
                                estate: ESTATE.investment,
                                transaction: TRANSACTION.rent,
                                location: 'iasi',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.location-constanta',
                            },
                            id: 'primaryMarketPopularLocationsConstanta',
                            href: '/[lang]/rezultate/inchiriere/investitii/constanta/constanta',
                            searchData: {
                                estate: ESTATE.investment,
                                transaction: TRANSACTION.rent,
                                location: 'constanta/constanta',
                            },
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_popular_locations',
                                market: 'primary',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.primary-market-popular-developers',
                    },
                    id: 'primaryMarketPopularDevelopers',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_developers',
                        market: 'primary',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-developers-bucuresti',
                                mobile: 'frontend.navbar.menu.location-bucuresti',
                            },
                            id: 'primaryMarketPopularDevelopersBucuresti',
                            href: '/bucuresti/companii/dezvoltatori/bucuresti/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_developers',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-developers-iasi',
                                mobile: 'frontend.navbar.menu.location-iasi',
                            },
                            id: 'primaryMarketPopularDevelopersIasi',
                            href: '/iasi/companii/dezvoltatori/iasi/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_developers',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-developers-cluj-napoca',
                                mobile: 'frontend.navbar.menu.location-cluj-napoca',
                            },
                            id: 'primaryMarketPopularDevelopersClujNapoca',
                            href: '/cluj/cluj-napoca/companii/dezvoltatori/cluj/cluj-napoca/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_developers',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-developers-timisoara',
                                mobile: 'frontend.navbar.menu.location-timisoara',
                            },
                            id: 'primaryMarketPopularDevelopersTimisoara',
                            href: '/timis/timisoara/companii/dezvoltatori/timis/timisoara/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_developers',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-developers-constanta',
                                mobile: 'frontend.navbar.menu.location-constanta',
                            },
                            id: 'primaryMarketPopularDevelopersConstanta',
                            href: '/constanta/companii/dezvoltatori/constanta/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_developers',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-developers-craiova',
                                mobile: 'frontend.navbar.menu.location-craiova',
                            },
                            id: 'primaryMarketPopularDevelopersCraiova',
                            href: '/sibiu/companii/dezvoltatori/dolj/craiova/craiova/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_developers',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-developers-brasov',
                                mobile: 'frontend.navbar.menu.location-brasov',
                            },
                            id: 'primaryMarketPopularDevelopersBrasov',
                            href: '/brasov/companii/dezvoltatori/brasov/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_developers',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.primary-market-developers-sibiu',
                                mobile: 'frontend.navbar.menu.location-sibiu',
                            },
                            id: 'primaryMarketPopularDevelopersSibiu',
                            href: '/sibiu/companii/dezvoltatori/sibiu/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_developers',
                                market: 'primary',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.primary-guide-header',
                    },
                    id: 'primaryMarketGuide',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_blog',
                        market: 'primary',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.block-article',
                            },
                            id: 'primaryMarketGuideBlockArticle',
                            href: 'https://www.storia.ro/blog/ghid-cumparare/ansambluri-rezidentiale/ansambluri-rezidentiale-bloc-monolit-vs-bloc-prefabricat',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.developer-rate-article',
                            },
                            id: 'primaryMarketGuideDeveloperRateArticle',
                            href: 'https://www.storia.ro/blog/ghid-cumparare/ansambluri-rezidentiale/rate-la-dezvoltator',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                market: 'primary',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.pay-attention-article',
                            },
                            id: 'primaryMarketGuideAttentionArticle',
                            href: 'https://www.storia.ro/blog/ghid-cumparare/ansambluri-rezidentiale/la-ce-sa-fiu-atent-cand-cumpar-un-apartament-bloc-nou',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                                market: 'primary',
                            },
                        },
                    ],
                    link: {
                        label: {
                            default: 'frontend.navbar.menu.see-all-button',
                            mobile: 'frontend.navbar.menu.primary-guide-header',
                        },
                        id: 'primaryMarketGuideSeeAll',
                        href: 'https://www.storia.ro/blog/ghid-cumparare/ansambluri-rezidentiale',
                        tracking: {
                            eventName: 'blog_click',
                            touchPointButton: 'header',
                            market: 'primary',
                        },
                    },
                },
            ],
        },
        {
            label: {
                default: 'frontend.navbar.menu.companies',
            },
            id: 'companies',
            tracking: {
                eventName: 'companies_click',
                touchPointButton: 'header',
            },
            submenu: [
                {
                    label: {
                        default: 'frontend.navbar.menu.companies-professionals',
                    },
                    id: 'companiesProfessionals',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_companies',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.companies-agency',
                            },
                            id: 'companiesProfessionalsAgency',
                            href: '/companii/agentii/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_companies',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.companies-developers',
                            },
                            id: 'companiesProfessionalsDevelopers',
                            href: '/companii/dezvoltatori/',
                            tracking: {
                                eventName: 'seo_link_click',
                                touchPointButton: 'header_companies',
                            },
                        },
                    ],
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.companies-question',
                    },
                    id: 'companiesQuestion',
                    isDesktopOnly: true,
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_companies',
                    },
                    link: {
                        label: {
                            default: 'frontend.navbar.menu.companies-add-new-ad',
                        },
                        isNexusButton: true,
                        id: 'companiesAddNewAd',
                        href: '/anunt-nou/categorie',
                        tracking: {
                            eventName: 'posting_form_click',
                            touchPointButton: 'header_companies',
                        },
                    },
                },
                {
                    label: {
                        default: 'frontend.navbar.menu.companies-guide-header',
                    },
                    id: 'companiesGuide',
                    tracking: {
                        eventName: 'title_click',
                        touchPointButton: 'header_blog',
                    },
                    submenu: [
                        {
                            label: {
                                default: 'frontend.navbar.menu.negotiation-article',
                            },
                            id: 'companiesGuideNegotiationArticle',
                            href: 'https://www.storia.ro/blog/ghid-vanzare/profesionisti/7-tehnici-de-negociere-utile-pentru-agentii-imobiliari',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.exclusive-contract-article',
                            },
                            id: 'companiesGuideExclusiveContractArticle',
                            href: 'https://www.storia.ro/blog/ghid-vanzare/profesionisti/contract-de-exclusivitate-pentru-vanzarea-unui-imobil-ce-trebuie-sa-stii',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                            },
                        },
                        {
                            label: {
                                default: 'frontend.navbar.menu.under-construction-article',
                            },
                            id: 'companiesGuideUnderConstructionArticle',
                            href: 'https://www.storia.ro/blog/ghid-vanzare/profesionisti/vanzarea-unui-imobil-in-constructie-cum-promovezi',
                            tracking: {
                                eventName: 'article_click',
                                touchPointButton: 'header',
                            },
                        },
                    ],
                    link: {
                        label: {
                            default: 'frontend.navbar.menu.see-all-button',
                            mobile: 'frontend.navbar.menu.companies-guide-header',
                        },
                        id: 'companiesGuideSeeAll',
                        href: 'https://www.storia.ro/blog/ghid-vanzare/profesionisti',
                        tracking: {
                            eventName: 'blog_click',
                            touchPointButton: 'header_companies',
                        },
                    },
                },
            ],
        },
        {
            label: {
                default: 'frontend.navbar.menu.credits',
            },
            id: 'finance',
            href: 'https://trai.storia.ro',
            target: '_blank',
            tracking: {
                eventName: 'finance_click',
                touchPointButton: 'head_bar',
            },
        },
    ],
    navUserMenu: {
        businessMember: [],
        unconfirmedUser: [
            {
                href: '/cont/inregistrare',
                label: 'frontend.navbar.menu.complete-registration',
            },
        ],
        regularUser: [
            {
                localisedHref: '/[lang]/contul-meu',
                label: 'frontend.navbar.menu.adverts',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_ads',
                },
            },
            {
                href: '/contul-meu/mesaje',
                label: 'frontend.navbar.menu.answers',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_messages',
                },
            },
            {
                href: '/contul-meu/portofel',
                localisedHref: '/[lang]/contul-meu/plati/istoric',
                label: 'frontend.navbar.menu.wallet',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_payments',
                },
            },
            {
                href: '/contul-meu/setari',
                label: 'frontend.navbar.menu.settings',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_settings',
                },
            },
        ],
        businessUser: [
            {
                localisedHref: '/[lang]/contul-meu/business/sumar',
                label: 'frontend.navbar.menu.summary',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_summary',
                },
            },
            {
                localisedHref: '/[lang]/contul-meu',
                label: 'frontend.navbar.menu.adverts',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_ads',
                },
            },
            {
                href: '/contul-meu/mesaje',
                label: 'frontend.navbar.menu.answers',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_messages',
                },
            },
            {
                localisedHref: '/[lang]/contul-meu/business/plati',
                label: 'frontend.navbar.menu.wallet',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_payments',
                },
            },
            {
                localisedHref: '/[lang]/contul-meu/setari',
                label: 'frontend.navbar.menu.settings',
                tracking: {
                    eventName: 'account_click',
                    touchPointButton: 'header_settings',
                },
            },
        ],
        businessAdmin: [],
        favourites: [
            {
                href: '/[lang]/salvat/anunturi',
                label: 'frontend.navbar.menu.favourite-adverts',
            },
            {
                href: '/[lang]/salvat/cautari',
                label: 'frontend.navbar.menu.favourite-searches',
            },
        ],
    },
    dateInput: {
        format: 'DD/MM/YYYY',
        placeholder: 'zz/ll/aaaa',
    },
    atlasRedirects: {
        editAd: '/anunt-nou/edit/{id}/',
        activateAd: '/plata/multipay/?id[0]={id}',
        extendAd: '/plata/multipay/?prolong[0]=1&id[0]={id}',
        promoteAd: '/plata/multipay/?id[0]={id}',
        adSingleMessage: '/contul-meu/answer/{id}',
        adMessages: '/contul-meu/mesaje/{id}',
        addNewUnit: '/vanzare/apartament/anunt-nou/?parent_id={parentId}',
        agencyRegistration: '/cont/inregistrare-companiei/agentie-imobiliara/',
        developerRegistration: '/cont/inregistrare-companiei/dezvoltator/',
        copyAd: '/vanzare/apartament/anunt-nou/?parent_id={parentId}&id={id}&copying=1',
        moreArticles: '/blog',
        offers: '/oferte/?search%5Bhomepage_ads%5D=1',
        developersPage: '/companii/dezvoltatori/',
        analytics: '',
        registrationSuccess: '',
        businessActivation: '/cont/activeaza',
        projectPostingForm: '/investitii/anunt-nou/',
        hciamLoginPage: '/api/internal/hciam/signin',
    },
    isProjectPostingFormMigrated: false,
    feedbackButton: {
        link: 'https://help.storia.ro/storiahelp/s/contactsupport',
    },
    baxter: {
        urlInit: 'https://cdn.slots.baxter.olx.org/storiaro/web/release/init.min.js',
        urlStyling: 'https://cdn.slots.baxter.olx.org/storiaro/web/release/init.css',
    },
    shouldShowSocialButtonsOnTopOnLoginModal: true,
    shouldShowExchangeRateTooltip: true,
    privacyPolicyLink: 'https://help.storia.ro/storiahelp/s/article/kA009000001vPDgCAM',
    features: {
        addOns: { enabledForUserTypes: [] },
    },
    externalUrl: {},
    cookiesPolicyLink: 'https://help.storia.ro/storiahelp/s/article/kA009000001vPG9CAM',
    regulationPolicyLink: 'https://help.storia.ro/storiahelp/s/article/kA009000001vPDfCAM',
    shouldUnregisterServiceWorker: false,
    postCode: {
        placeholder: '123456',
    },
    topLocations: {
        atlasLocations: [],
        locationServiceLocations: [
            {
                name: 'bucuresti',
                label: 'Bucuresti',
                areaName: 'Bucuresti',
            },
            {
                name: 'constanta/constanta',
                label: 'Constanta, Constanta',
                areaName: 'Constanta',
            },
            {
                name: 'cluj/cluj--napoca',
                label: 'Cluj-Napoca, Cluj',
                areaName: 'Cluj-Napoca',
            },
            {
                name: 'timis/timisoara',
                label: 'Timisoara, Timis',
                areaName: 'Timisoara',
            },
            {
                name: 'iasi/iasi',
                label: 'Iasi, Iasi',
                areaName: 'Iasi',
            },
        ],
    },
    validation: {
        postCode: /^\d{6}$/,
        tin: 'tin-ro',
        regon: 'regon-ro',
    },
    shouldUseTypologyInsteadOfNumberOfRooms: false,
};
